<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Tous en scène</h2>
        <h5>Révélez le talent qui sommeille en vous…, 20 décembre 2020</h5>

          <br>
          <p>
            <strong class="emphase">De quoi ça parle :</strong> « Tous en scène » raconte comment un koala tente de sauver l’avenir de son théâtre en organisant un concours de chant.
          </p>

          <p>
            <strong class="emphase">Mon avis :</strong> Un très bon dessin-animé avec une belle morale, de l’humour et de l’émotion. Les personnages sont tous exploités, aucun n’est laissé pour compte. En plus d’être une histoire chouette, la musique est excellente.
          </p>

          <br>

          <p>
            Un programme très sympathique pour un bon moment familial ou pas, qui vous donnera envie de bondir de votre canapé pour chanter et danser.
          </p>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-tes.jpg" alt="">
        </div>

        <p class="signature text-end"><strong>Angèle</strong></p>
      </article>
    </div>
  </div>
</section>


