<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Détroit</h2>
        <h5>La ségrégation dans toute son horreur, 22 octobre 2020</h5>

        <p>
          Film inspiré de faits réels - 2h23 - pour les 16 ans et plus
        </p>

        <br>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-detroit.jpg" alt="">
        </div>

        <br>

        <p>
          Je reviens avec un film, non que dis-je un monument du cinéma, une claque cinématographique : Détroit.
          J'ai besoin de regarder des films ou des séries qui font évoluer mes connaissances et ma culture générale, me font vibrer, réflechir et m'emportent dans les émotions.
        </p>

        <p>
          Été 1967, les États-Unis connaissent une vague d’émeutes sans précédent. La guerre du Vietnam, vécue comme une intervention néocoloniale et la ségrégation raciale nourrissent la contestation.
          <br>
          À Detroit, alors que le climat est insurrectionnel depuis quelques jours, des coups de feu sont entendus en pleine nuit à proximité d’une base de la Garde nationale. Les forces de l’ordre encerclent l’Algiers Motel d’où semblent provenir les détonations. Bafouant toute procédure, les policiers soumettent une poignée de clients de l’hôtel à un interrogatoire sadique pour extorquer leurs aveux. Le bilan sera très lourd : trois hommes, non armés, seront abattus à bout portant, et plusieurs autres blessés…
        </p>

        <p>
          Dès 1964, la ségrégation raciale est un fléau aux USA, les afro-américains qui croyaient, en migrant du sud au nord des Etats-Unis, trouver l'égalité des chances et un nouveau départ dans la vie, comprennent vite qu'ils restent pour les blancs une "sous-race" et doivent ne jamais se mélanger dans le quotidien avec eux.
        </p>

        <p>
          On est littéralement pris à la gorge par ce climat ambiant, terriblement anxiogène qui nous tétanise ! La justesse du jeu de ces jeunes acteurs afro-américains n'a rien à envier à tous les grands comédiens des USA. Ils m'ont conquis et entrainé dès la première minute dans leur vie de jeunes adultes noirs avec leurs projets de réussite, leurs combats pour leurs droits civiques et leurs rêves comme chacun de nous à cet âge. Stupéfiant !!!
          <br>
          J'ai adoré la musique et les chants de l'époque motown notament la voix extraordinaire de Larry qui me fait encore vibrer au moment ou j'écris cet article.
          Bref, dans ce film tout nous est dépeint sans concession aucune. Les images d’archives insérées intelligemment renforcent encore la crédibilité des actes inqualifiables commis contre la communauté noire ! Une histoire bouleversante, véritable témoignage du racisme et de la barbarie humaine à ne pas louper sur Netflix !
        </p>

        <p>
          Conclusion : n'oublions jamais que le sang qui coule dans nos veines est rouge pour tous les humains sans distinction. Nous respirons le même air. Nous avons les mêmes projets de vie : réussir et être heureux sur terre. Toutes nos différences extérieures ne servent qu'à nous diviser, nous blesser, nous éloigner les uns les autres pour ne pas voir nos propres défauts et responsabilités dans les choix non-assumés de nos vies !
          <br>
          Nous avons TOUS besoin de respect, de bienveillance et de reconnaissance... Ceux qui pensent qu'ils peuvent vivre sans cela, se mentent à eux même pour cacher leur fragilité.
          Bravo Netflix pour la mise à disposition de films et séries qui nous permettent à tous de grandir et d’évoluer sur le vivre ensemble, unis et sans différence raciale.
          <br>
          Bon visionnage sur Netflix !
        </p>

        <br>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/YSxkUjRqUcY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>Charly</strong></p>
      </article>

    </div>

  </div>

</section>





