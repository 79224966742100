<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>The Empress Ki</h2>
        <h5>L'Asie médiévale à travers le destin d'une femme d'exception, 10 février 2021</h5>
        <p>
          Le parcours de l’Impératrice Ki, de la dynastie Yuan, fondée par Genghis Kahn, semé de tragédies, passions et dangers en tous genres.
        </p>
        <br>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-the_empress_ki.jpg" alt="">
        </div>

        <br>

          <p>
            Avec cette série attachante, Netflix nous plonge dans l’Asie médiévale à travers le destin d’une femme d’exception.
          </p>

          <br>

          <iframe width="560" height="315" src="https://www.youtube.com/embed/NHW8Cjtsu8Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

          <p>
            51 épisodes d’un drama sud-coréen en V.O.S.T., cela ressemble à un défi, et pourtant ! Dès le premier épisode, on se retrouve happé par les mésaventures de Seung- nyang, jeune femme née à Goryeo, offerte en cadeau et échappant miraculeusement à la mort pour vivre de multiples aventures, avant de se retrouver au sein de la cour impériale mongole de l’empereur Ta Hwan (Togonh Temür) et de finalement devenir une Impératrice de la Dynastie Yuan (1279 – 1368).
          </p>

          <p>
            Le premier épisode s’ouvre d’emblée sur le mariage, qui semble teinté de tristesse: la jeune femme pleure, un inconnu la fixe dans la foule... à peine nous demandons-nous pourquoi, que nous sommes ramenés des années en arrière, au tout début du chemin de vie incroyable qui a mené Seung- nyang jusque-là.
          </p>

          <p>
            Et très vite, nous tombons sous l’emprise de cette série très attachante.
          </p>

          <p>
            Tout d’abord, l’héroine, Seung- nyang, est une vraie héroine, tour à tour orpheline, chef de gang, soldat, garde du corps, puis servante, complotiste, et j’en passe. L’actrice Ha Ji-won exprime magnifiquement les facettes et la sensibilité multiple de la femme forte qu’elle incarne: elle peut être drôle, humble, totalement badass, autoritaire ou encore fleur bleue avec la même grâce et la même crédibilité.
Les autres acteurs ne sont pas en reste, chacun des principaux personnages est profond et nuancé; les échanges de regard ou mêmes les non-dits sont bien souvent extraordinaires de justesse. Un coup de cœur particulier pour Taltal qui est pour moi le personnage le plus émouvant, au regard de ses difficultés à rester fidèle à ses valeurs tout en jonglant avec ses dilemmes personnels.
          </p>

          <p>
            Mais les histoires d’amitié et d’amour (et de haine) ne représentent qu’un des aspects de la série. Le contexte historique médiéval, les batailles, les conquêtes, les impitoyables guerres de pouvoir, les destitutions, les injustices, retournements et trahisons sont tout aussi captivants. Les décors et costumes sont splendides; les fanas de fresques historiques ou de complots politiques apprécieront eux aussi la série, car le dosage entre romance et politique est parfait, aucune part ne faisant de l’ombre à l’autre.
          </p>

          <p>
            Enfin, le rythme est étonnamment bien maîtrisé. Les creux sont extrêmement rares tout au long de la série. Oscillant entre lenteurs bien dosées (comme lors des dialogues officiels) et rebondissements inattendus, le rythme suivi nous permet de ne pas nous ennuyer une seconde et d’attendre avec impatience l’épisode suivant.
          </p>

          <p>
            Et finalement, 51 épisodes... ça semble presque trop court.
          </p>

        <br>

        <p class="signature text-end"><strong>S. Priétula</strong></p>
      </article>
    </div>
  </div>
</section>

<p>article-the-empress-ki works!</p>
