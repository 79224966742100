<section class="svod py-5">
  <div class="container">
    <!-- SVOD title -->
    <div class="row ">

        <h1 class="svod-h1">Fans de SVOD by <strong class="svod-title">FDN Community</strong></h1>

    </div>

    <!-- Carousel: A LA UNE -->
    <h2>Au programme du mois de Novembre</h2>
    <div class="row m-5">
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../../../assets/img/prog-netflix-nov.jpeg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/prog-amazon-nov.jpeg" class="d-block w-100" alt="...">
    </div>
    <!-- <div class="carousel-item">
      <img src="../../../../assets/img/programme_ocs_juin.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/programme_disney_juin.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/programme_disney_juin_2.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/programme_disney star_juin.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/programme_disney star_juin_2.jpg" class="d-block w-100" alt="...">
    </div> -->
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </div>


    <!-- <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../../../../assets/img/star-séries.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/img/star-films.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/img/star-films_2.jpg" class="d-block w-100" alt="...">
          </div>
          <div class="carousel-item">
            <img src="../../../../assets/img/star-film_3.jpg" class="d-block w-100" alt="...">
          </div>
        </div>-->



    <!-- SVOD buttons-->
    <!-- <div class="row svod-btn">
      <div class="col-lg-8 mx-auto d-flex justify-content-around sortBtn flex-wrap">
        <a class="btn btn-black text-uppercase filter-btn m-2" data-filter="all">Tous</a>
        <a class="btn btn-black text-uppercase filter-btn m-2" data-filter="all">Netflix</a>
        <a class="btn btn-black text-uppercase filter-btn m-2" data-filter="all">OCS</a>
        <a class="btn btn-black text-uppercase filter-btn m-2" data-filter="all">Amazon Prime Videos</a>
        <a class="btn btn-black text-uppercase filter-btn m-2" data-filter="all">Disney Plus</a>
      </div>
    </div> -->

    <!-- search box -->
    <!-- <div class="row svod-search-box">
      <div class="col-10 mx-auto col-md-6">
        <form>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text search-box material-icons" id="search-icon">
                search
              </span>
            </div>
            <input type="text" class="form-control" placeholder="série ou film" id="search item">
          </div>
        </form>
      </div>
    </div> -->

    <!-- svod card -->
    <div class="row svod-items" id="svod-items">
      <!-- Netflix -->
      <div class="col-10 col-sm-6 col-lg-3 mx-auto my-3 svod-item netflix" data-item="netflix">
        <div class="card single-item">
          <div class="img-container">
            <img src="../../../../assets/img/svod_netflix1.jpg" alt="Netflix" class="card-img-top svod-img">
            <a href="/svod-netflix">
              <span class="material-icons svod-item-icon">description</span>
            </a>

          </div>
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h5 class="text-center">Fans de Netflix</h5>
              <a href="https://www.facebook.com/groups/161999601190065" target="_blank">
                <span class="material-icons svod-item-facebook">facebook</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- OCS -->
      <div class="col-10 col-sm-6 col-lg-3 mx-auto my-3 svod-item ocs" data-item="ocs">
        <div class="card single-item">
          <div class="img-container">
            <img src="../../../../assets/img/svod_ocs.jpg" alt="OCS" class="card-img-top svod-img">
            <a href="/svod-ocs">
              <span class="material-icons svod-item-icon">description</span>
            </a>

          </div>
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h5 class="text-center">Fans de OCS</h5>
              <a href="https://www.facebook.com/groups/2464539473565298/?modal=false&should_open_composer=false" target="_blank">
                <span class="material-icons svod-item-facebook">facebook</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <!-- Amazon Prime -->
      <div class="col-10 col-sm-6 col-lg-3 mx-auto my-3 svod-item amazon" data-item="amazon">
        <div class="card single-item">
          <div class="img-container">
            <img src="../../../../assets/img/svod_prime-video.png" alt="Amazon Prime videos" class="card-img-top svod-img">
            <a href="/svod-amazon-video">
              <span class="material-icons svod-item-icon">description</span>
            </a>
          </div>
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h5 class="text-center">Fans de Amazon Prime</h5>
              <a href="https://www.facebook.com/groups/526724821323968?modal=false&should_open_composer=false" target="_blank">
                <span class="material-icons svod-item-facebook">facebook</span>
              </a>
            </div>
          </div>
        </div>
      </div>

       <!-- Disney + -->
      <div class="col-10 col-sm-6 col-lg-3 mx-auto my-3 svod-item disney-plus" data-item="disney-plus">
        <div class="card single-item">
          <div class="img-container">
            <img src="../../../../assets/img/svod_disney-plus.png" alt="Disney Plus" class="card-img-top svod-img">
            <a href="/svod-disney-plus">
              <span class="material-icons svod-item-icon">description</span>
            </a>
          </div>
          <div class="card-body">
            <div class="card-text d-flex justify-content-between">
              <h5 class="text-center">Fans de Disney +</h5>
              <a href="https://www.facebook.com/groups/519156228721257" target="_blank">
                <span class="material-icons svod-item-facebook">facebook</span>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</section>
