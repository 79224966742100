import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-svod',
  templateUrl: './main-svod.component.html',
  styleUrls: ['./main-svod.component.sass']
})
export class MainSvodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
