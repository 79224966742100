import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-cercle-poete-disparu',
  templateUrl: './article-cercle-poete-disparu.component.html',
  styleUrls: ['./article-cercle-poete-disparu.component.sass']
})
export class ArticleCerclePoeteDisparuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
