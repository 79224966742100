<!-- main-banner -->
<div class="container-fluid">
  <div class="row max-height justify-content-center align-items-center">
    <div class="col-10 mx-auto banner text-center">
      <h1 class="main-banner">
        <strong class="banner-title">Bienvenue sur FDN Community</strong>
      </h1>
      <!-- <a class="btn banner-link text-uppercase my-2">Explore</a> -->
    </div>
  </div>
</div>
