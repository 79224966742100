import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-this-is-us',
  templateUrl: './article-this-is-us.component.html',
  styleUrls: ['./article-this-is-us.component.sass']
})
export class ArticleThisIsUsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
