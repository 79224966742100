<section class="netflix py-5">
  <div class="container">

    <!-- Netflix title -->
    <div class="row text-center title">
      <h1 class="netflix-h1">Fans de <strong class="netflix-title">Netflix</strong></h1>
    </div>

    <!-- navbar -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="series-tab" data-bs-toggle="tab" href="#series" role="tab" aria-controls="series" aria-selected="true">Séries</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="films-tab" data-bs-toggle="tab" href="#films" role="tab" aria-controls="films" aria-selected="false">Films</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="drama-tab" data-bs-toggle="tab" href="#drama" role="tab" aria-controls="drama" aria-selected="false">Dramas</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="documentaires-tab" data-bs-toggle="tab" href="#documentaires" role="tab" aria-controls="documentaires" aria-selected="false">Documentaires</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="animes-tab" data-bs-toggle="tab" href="#animes" role="tab" aria-controls="animes" aria-selected="false">Animés</a>
      </li>
    </ul>

    <!-- article cards -->
    <div class="tab-content" id="myTabContent">

      <!-- Series -->
      <div class="tab-pane fade show active" id="series" role="tabpanel" aria-labelledby="serie-tab">

        <!-- cards -->
        <div class="row">

          <!-- article Dracula -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Mini-série</div>
              <div class="card-body">
                <h5 class="card-title">Dracula</h5>
                <p class="card-text">Le mythe de Dracula revisité et dépoussiéré en trois volets efficaces.</p>
                <a [routerLink]="['/article-dracula']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#série #vampire #surnaturel #horreur #dracula</div>
            </div>
          </div>

          <!-- article Family business -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Série</div>
              <div class="card-body">
                <h5 class="card-title">Family Business - C'est l'histoire d'une beucherie</h5>
                <p class="card-text">Un trentenaire, entrepreneur raté décide de transformer la boucherie casher de son père</p>
                <a [routerLink]="['/article-family_business']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#famille #business #darmon #cohen</div>
            </div>
          </div>

          <!-- article The Crown -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Série historique</div>
              <div class="card-body">
                <h5 class="card-title">The Crown - La vie pas si rêvée de la famille royale d'Angleterre</h5>
                <p class="card-text">Découvrez les dessous du règne le plus long d'Angleterre</p>
                <a [routerLink]="['/article-the_crown']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#reine #angleterre #familleroyale #thecrown</div>
            </div>
          </div>

          <!-- article 13 reasons why -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Série dramatique</div>
              <div class="card-body">
                <h5 class="card-title">13 reasons why</h5>
                <p class="card-text">Une défunte qui laisse des cassettes enregistrées</p>
                <a [routerLink]="['/article-thirteen_reasons_why']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#drame #ados</div>
            </div>
          </div>

          <!-- article after life-->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Série</div>
              <div class="card-body">
                <h5 class="card-title">After Life</h5>
                <p class="card-text">Les sujets graves de la vie évoqués avec humour</p>
                <a [routerLink]="['/article-after-life']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#seriecomique #seriebritannique #humour </div>
            </div>
          </div>

          <!-- aricle Lupin -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Série</div>
              <div class="card-body">
                <h5 class="card-title">Omar Sy, le gentleman cambrioleur</h5>
                <p class="card-text">Omar SY incarne un nouveau personnage qui n'est autre qu'un cambrioleur dans la nouvelle série Originale Netflix “Lupin dans l’ombre d&#39;Arsène&quot;</p>
                <a [routerLink]="['/article-lupin']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#netflix #nouveauté #originalnetflix</div>
            </div>
          </div>


        <!-- article-soa -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">
                Série
              </div>
              <div class="card-body">
                <h5 class="card-title">Sons of Anarchy</h5>
                <p class="card-text">Si vous êtes intéressés par les histoires mêlant guerre de territoires, humour, amour et trahison, cette série est faite pour vous... </p>
                <a [routerLink]="['/article-soa']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#netflix #bikers #drame</div>
            </div>
          </div>

        <!-- article dear white people -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">
                Série
              </div>
              <div class="card-body">
                <h5 class="card-title">Dear white people</h5>
                <p class="card-text">Le racisme de notre siècle présent</p>
                <a [routerLink]="['/article-dear_white_people']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#noir(es) #racisme #usa</div>
            </div>
          </div>

        </div>
      </div>

      <!-- Films -->
      <div class="tab-pane fade" id="films" role="tabpanel" aria-labelledby="films-tab">
        <div class="row">

          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Film d'animation</div>
              <div class="card-body">
                <h5 class="card-title">Tous en scène</h5>
                <p class="card-text">Un programme famillial qui vous fera chanter et danser</p>
                <a [routerLink]="['/article-tous-en-scene']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#netflix #animation #famille</div>
            </div>
          </div>

          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Film africain inspiré de faits réels</div>
              <div class="card-body">
                <h5 class="card-title">La convocation</h5>
                <p class="card-text">Une étudiante nigérienne tente de dénoncer une tentative de viol sur sa personne par l'un de ses profs</p>
                <a [routerLink]="['/article-la-convocation']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#viol #femme </div>
            </div>
          </div>



          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Film historique</div>
              <div class="card-body">
                <h5 class="card-title">Victoria et Abdul</h5>
                <p class="card-text">Une reine anglaise rencontre un homme simple d'Inde</p>
                <a [routerLink]="['/article-victoria-et-abdul']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#historique #angleterre #queen #inde</div>
            </div>
          </div>

          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Film policier</div>
              <div class="card-body">
                <h5 class="card-title">Bronx</h5>
                <p class="card-text">A Marseille, corruption de flics et guerre des gangs ne font pas bon ménage</p>
                <a [routerLink]="['/article-bronx']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#police #gang #marseille</div>
            </div>
          </div>

          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Inspiré de faits réels</div>
              <div class="card-body">
                <h5 class="card-title">Détroit</h5>
                <p class="card-text">La mort de trois afro-américains déclenche des émeutes sanglantes</p>
                <a [routerLink]="['/article-detroit']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#racisme #afro #justice</div>
            </div>
          </div>


        </div>
      </div>

      <!-- Drama -->
      <div class="tab-pane fade" id="drama" role="tabpanel" aria-labelledby="drama-tab">
        <div class="row">

          <!-- article-drama -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Culture</div>
              <div class="card-body">
                <h5 class="card-title">Qu'est-ce-qu'un drama?</h5>
                <p class="card-text">Les dramas sont de plus en présents sur Netflix, mais de quoi est-il question ?</p>
                <a [routerLink]="['/article-drama']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#netflix #kdrama #vost</div>
            </div>
          </div>

          <!-- article The Empress Ki -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Drama historique</div>
              <div class="card-body">
                <h5 class="card-title">The Empress Ki</h5>
                <p class="card-text">Asie médiévale à travers le destin d'une femme d'exception</p>
                <a [routerLink]="['/article-the_empress_ki']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#drama #histoire #asie #romance #politique</div>
            </div>
          </div>

        </div>
      </div>

      <!-- Documentaires -->
      <div class="tab-pane fade" id="documentaires" role="tabpanel" aria-labelledby="documentaires-tab">
        <div class="row">

          <!-- article -->
          <div class="col-12 col-lg-4">

            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Documentaire culinaire</div>
              <div class="card-body">
                <h5 class="card-title">Les tribulations culinaires de Phil</h5>
                <p class="card-text">Voyage, humour et gourmandise sont aux rendez-vous </p>
                <a [routerLink]="['/article-les-tribulations-culinaires-de-phil']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#voyage #gourmandise #netflix </div>
            </div>

          </div>

            <!-- article -->
            <div class="col-12 col-lg-4">

              <div class="card text-center" style="max-width: 540px;">
                <div class="card-header">Documentaire historique</div>
                <div class="card-body">
                  <h5 class="card-title">Les secrets de la tombe de Saqqarah</h5>
                  <p class="card-text">4 000 ans nous contemplent</p>
                  <a [routerLink]="['/article-les-secrets-de-la-tombe-de-saqqarah']" class="btn btn-dark">Lire</a>
                </div>
                <div class="card-footer text-muted">#histoire #egypte</div>
              </div>
            </div>

        </div>
      </div>

       <!-- Animes -->
      <div class="tab-pane fade" id="animes" role="tabpanel" aria-labelledby="animes-tab">
        <div class="row">

          <!-- article -->
          <div class="col-12 col-lg-4">

            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Long métrage</div>
              <div class="card-body">
                <h5 class="card-title">Je veux manger ton pancréas</h5>
                <p class="card-text">Tout les oppose... La maladie les rapproche</p>
                <a [routerLink]="['/article-je_veux_manger_ton_pancreas']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#animé #netflix</div>
            </div>

          </div>

          <!-- article -->
            <div class="col-12 col-lg-4">

              <div class="card text-center" style="max-width: 540px;">
                <div class="card-header">Série</div>
                <div class="card-body">
                  <h5 class="card-title">Your lie in April</h5>
                  <p class="card-text">L'animé d'une vie, qui nous ramène à la réalité</p>
                  <a [routerLink]="['/article-your_lie_in_april']" class="btn btn-dark">Lire</a>
                </div>
                <div class="card-footer text-muted">#animé #netflix</div>
              </div>
            </div>

        </div>
      </div>
    </div>

  </div>
</section>

<main>
  <router-outlet></router-outlet>
</main>

