import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-marvel-project-heros',
  templateUrl: './article-marvel-project-heros.component.html',
  styleUrls: ['./article-marvel-project-heros.component.sass']
})
export class ArticleMarvelProjectHerosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
