<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Qu'est-ce qu'un drama ?</h2>
        <h5>Les dramas sont de plus en plus présents sur Netflix mais qu'est-ce qu'un drama ? et plus exactement ce dont je vais vous parler, un Kdrama ? 4 janvier 2021</h5>
        <p>
          Les Kdramas sont des séries coréennes ( K = Korean ) qui comportent une seule saison en général avec des épisodes d’environ 1 heure chacun en vost.
          <br>
          <p>
            Chaque drama est composé de 16 à 20 épisodes en moyenne mais il existe des dramas plus longs qui comportent 40 à 50 épisodes voire une centaine d'épisodes pour les plus longs. Il existe également des webdramas avec une durée de 15 à 20 minutes chacun.
          </p>
          <br>

          <p>
            Nous pouvons classer les dramas par genre :
          </p>
          <ul>
            <li>Romance</li>
            <li>Historique </li>
            <li>Comédie </li>
            <li>Thriller ( policier )</li>
            <li>Monde médical </li>
            <li>Univers scolaire </li>
            <li>Fantastique </li>
          </ul>

          <div class="article_img">
            <img class="img-fluid" src="../../../../../assets/img/article-drama-img2.jpg" alt="">
          </div>

          <p>
            La plupart des séries sont diffusées après seulement quelques épisodes tournés, ce qui fait qu'elles sont en général tournées en"  live shooting", soit au fur et à mesure avec peu d'épisodes d'avance, ce qui permet de changer l'histoire selon les réactions de l'audience.
          </p>

          <div class="article_img">
            <img class="img-fluid" src="../../../../../assets/img/article-drama-img1.jpg" alt="">
          </div>

          <p>
            Ci-dessous, le trailer du drama « Healer", un exemple qui mélange plusieurs genres (romance action intrigue).
          </p>

          <iframe width="560" height="315" src="https://www.youtube.com/embed/EcOhZLULyFA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>Neopha</strong></p>
      </article>
    </div>
  </div>
</section>
