<section class="about py-5" id="about">
  <div class="container">
    <div class="row max-height">

      <div class="col-12 mx-auto my-5">

        <h1>A propos de
          <strong class="about-title">FDN Community</strong>
        </h1>

        <div class="row">
          <article class="my-4 col-lg-6 text-muted about-info">
            <h3>En 2016</h3>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Trois copains s'abonnent à la plate-forme <strong>Netflix</strong> et ont un mal fou à trouver des suggestions pertinentes sur cet immense catalogue afin de répondre rapidement à leurs goûts en matière de séries. Après moult recherches sur les réseaux sociaux, pas plus d'aide efficace et pertinente ! Là est née l'idée de créer une communauté regroupant les Fans de Netflix sur Facebook. Le défi est relevé et la page FB <strong>Fans de Netflix</strong> est lancée le 31 octobre 2017. Le succès et l'audience de cette page sont rapidement au rendez-vous. Le 13 janvier 2018 un premier groupe privé FB est associé à cette page à la demande des fans afin d'échanger plus librement sur les séries Netflix avec la règle absolue du <strong>0 spoil</strong>.
            </p>
          </article>

          <article class="my-4 col-lg-6 text-muted about-info">
            <h3>En 2020</h3>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Pour répondre à la demande des fans multiSVOD, d'autres groupes ont été constitués sur les catalogues <strong>OCS</strong>, <strong>Disney Plus</strong>  et <strong>Amazon Prime</strong>. Un groupe <strong>FDN Live</strong> existe également depuis le premier confinement pour partager des Lives sur des sujets musicaux, mettre en lumière des talents, donner des bons plans, créer du <strong>lien social</strong> et du <strong>partage</strong>. L'ensemble des différents supports nous a amené à créer une seule communauté de SVOD désignée sous le nom de <strong>FDN Community</strong>.
                Les bases qui rassemblent cette communauté sont : bienveillance, respect et convivialité. Les membres sont principalement issus du territoire français mais une partie vient également de l'Europe, l'Afrique, l'Asie et l'Amérique.
            </p>
          </article>

          <div class="col-10 col-lg-6 my-5 align-self-center">

            <div class="about-img_container">
              <img src="../../../../assets/img/main-fdn-presentation.jpg" alt="Nos réseaux sociaux" class="img-fluid">
              <div class="social-network">
                <p class="info_social-network">Suivez-nous sur nos réseaux sociaux<span class="material-icons touch_app">touch_app</span></p>
                <ul>
                  <!-- facebook -->
                  <li>
                      <a href="https://www.facebook.com/fans2netflix" target="_blank">
                        <img src="../../../../assets/img/facebook.png" alt="facebook" class="about_social-network">
                      </a>
                  </li>
                  <!-- twitter -->
                  <li>
                      <a href="https://twitter.com/Fans2Netflix" target="_blank">
                        <img src="../../../../assets/img/twitter.png" alt="twitter" class="about_social-network">
                      </a>
                  </li>
                  <!-- instagram -->
                  <li>
                      <a href="https://instagram.com/fans2netflix" target="_blank">
                        <img src="../../../../assets/img/instagram.png" alt="instagram" class="about_social-network">
                      </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <article class="my-4  col-lg-6 text-muted about-info">
            <h3>En 2021</h3>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;Notre premier groupe international <strong>FDN Canada</strong> verra le jour et sera le premier de FDN Community portant sur un catalogue Netflix étranger.
            </p>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;3 ans après ses premiers balbutiements, l'aventure FDN est une communauté comptant :</p>
              <ul>
                <li>80 000 fans (tous supports confondus)</li>
                <li>8 millions de vues globales des publications FDN</li>
                <li>1 page facebook, 5 groupes associés, 1 compte Instagram, 1 compte Twitter et un site internet</li>
                <li>une Team bénévole de 12 personnes (administrateurs et modérateurs) pour gérer l'ensemble des supports de la communauté</li>
                <li>nos membres représentent 60% de femmes et 40% d'hommes dont la majorité ont entre 25 et 35 ans.</li>
              </ul>
            <p>&nbsp;&nbsp;&nbsp;&nbsp;L'aventure extraordinaire continue. FDN ne cesse de s'organiser pour répondre à une réelle demande des Fans de Netflix du monde entier. Notre but est de faire de cette communauté un outil indispensable pour passer un bon moment devant Netflix en famille ou entre copains.
            </p>
            <p class="text-end signature">
              <strong>Les fondateurs FDN Community</strong>
            </p>
          </article>
        </div>

      </div>

    </div>
  </div>
</section>

