<footer class="footer font-small bg-dark text-white">

  <div class="container-fluid contact-box">

    <div class="row d-flex justify-content-around align-items-center p-3">
        <div class="contact col-12 col-lg-5">
        <div class="email d-flex align-items-center">
          <span class="material-icons contact-icon">email</span>
          <span>fansdenetflixfrance@gmail.com</span>
        </div>
      </div>

      <div class="about-us col-12 col-lg-4">
        <h4 class="title">A propos de nous</h4>
        <p>
          FDN Community rassemble près de 80 000 fans, avec une page Facebook, cinq groupes associés, un compte Instagram et un compte Twitter. N'hésitez pas à nous rejoindre. Nos crédos sont: bienveillance, respect, convivialité et Zéro spoil.
        </p>
        <div class="social-media d-flex justify-content-start py-3">
          <a href="https://www.facebook.com/fans2netflix" target="_blank">
            <img src="../../../../assets/img/social_media_logo_facebook_icon-icons.com_59059.ico" alt="" class="logo-rs">
          </a>
          <a href="https://instagram.com/fans2netflix" target="_blank">
            <img src="../../../../assets/img/social_media_logo_instagram_icon-icons.com_59062.ico" alt="" class="logo-rs">
          </a>
          <a href="https://twitter.com/Fans2Netflix" target="_blank">
            <img src="../../../../assets/img/social_media_logo_twitter_icon-icons.com_59061.ico" alt="" class="logo-rs">
          </a>

        </div>
      </div>
    </div>
  </div>

  <div class="footer-copyright text-center py-3">FDN &reg; est une marque déposée - &copy;2021 - Created with
    <span class="favorite material-icons">favorite</span> at Bordeaux & Montréal
  </div>
</footer>

