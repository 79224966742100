<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Bronx</h2>
        <h5>La police sous toutes ses contradictions, 30 octobre 2020</h5>

        <p>
          Film policier de 2020 de 1h56 pour les 16 ans et plus
        </p>

        <p>
          Dans un domaine qui ne correspond pas à mes préférences habituelles sur Netflix, je voudrais vous parler du film "Bronx", d’Olivier Marchal. Le grand flic, le grand auteur et le talentueux réalisateur.
        </p>

        <p>
          Déjà c’est français alors cocorico.
          La palette d’acteurs est extraordinaire : Gérard Lanvin, Jean Reno, Kaaris, Stanislas Merhar, Claudia Cardinal, Moussa Maaskri....
        </p>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-bronx.jpg" alt="">
        </div>

        <p>
          Le film se passe au sein de la police marseillaise (BRI, antigang..). Il est question de flics ripoux pas très nets et très mal dans leur peau au quotidien. Ils ont avec une vie affective appauvrie voir inexistante. Rajoutez au scénario la guerre des gangs violente des quartiers nord de Marseille, beaucoup de testostérone et la poudrière est en place.
        </p>

        <p>
          L’ambiance du film est typique des polars, bien huilée et les dialogues sont croquants à souhait. Des images chocs nous rappellent une triste réalité: ce n’est pas que de la fiction, bien malheureusement.
        </p>
        <p>
          La façon également de commencer le film avec un écran noir donne des le départ le contexte tendu et suffoquant de l’intrigue mais chuut.
        </p>
        <p>
          Franchement je ne peux que me réjouir de trouver cette pépite made in France sur Netflix et vous le recommande mais sans vos jeunes enfants à proximité.
        </p>
        <p>
          Pour ceux qui ont vu « Les Lyonnais » cette autre pépite française, c’est dans la même veine.
          <br>
          Bon visionnage sur Netflix
        </p>

        <br>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/LTt-hVUzSJI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>Charly</strong></p>
      </article>

    </div>

  </div>

</section>





