import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-victoria-et-abdul',
  templateUrl: './article-victoria-et-abdul.component.html',
  styleUrls: ['./article-victoria-et-abdul.component.sass']
})
export class ArticleVictoriaEtAbdulComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
