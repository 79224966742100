<section class="disney-plus">
  <div class="container py-5">

    <!-- disney plus title -->
    <div class="row text-center title">
      <h1 class="ocs-h1">Fans de <strong class="ocs-title">Disney Plus</strong></h1>
    </div>

    <div class="row m-5">
      <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../../../../assets/img/star-séries.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/star-films.jpg" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../../../../assets/img/star-films_2.jpg" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
    </div>

    <!-- navbar -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="films-tab" data-bs-toggle="tab" href="#films" role="tab" aria-controls="films" aria-selected="true">Films</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="documentaires-tab" data-bs-toggle="tab" href="#documentaires" role="tab" aria-controls="documentaires" aria-selected="false">Documentaires</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="star-tab" data-bs-toggle="tab" href="#star" role="tab" aria-controls="star" aria-selected="true">Star</a>
      </li>
    </ul>

    <div class="tab-content" id="myTabContent">

      <!-- Series -->
      <!-- <div class="tab-pane fade show active" id="series" role="tabpanel" aria-labelledby="serie-tab"> -->
        <!-- cards -->
        <!-- <div class="row"> -->

          <!-- article Chernobyl -->
          <!-- <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Mini-Série</div>
              <div class="card-body">
                <h5 class="card-title">Chernobyl</h5>
                <p class="card-text">(Re)vivez la tristement célèbre catastrophe nucléaire</p>
                <a [routerLink]="['/article-chernobyl']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#ocs #catastrophe</div>
            </div>
          </div> -->

          <!-- article -->
          <!-- <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Série fantastique</div>
              <div class="card-body">
                <h5 class="card-title">Game of Throne</h5>
                <p class="card-text">Winter is coming</p>
                <a [routerLink]="['/article-game-of-thrones']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#dragon #chevalier #mortvivant</div>
            </div>
          </div> -->

          <!-- article -->
          <!-- <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Série</div>
              <div class="card-body">
                <h5 class="card-title">Big little lies</h5>
                <p class="card-text">Trois femmes, mères à la vie parfaite... En surface seulement</p>
                <a [routerLink]="['/article-big-little-lies']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#ocs #mere #femmes</div>
            </div>
          </div> -->

        <!-- article -->
        <!-- <div class="col-12 col-lg-4">

          <div class="card text-center" style="max-width: 540px;">
            <div class="card-header">
              Culture
            </div>
            <div class="card-body">
              <h5 class="card-title">Qu'est-ce-qu'un drama?</h5>
              <p class="card-text">Les dramas sont de plus en présents sur Netflix, mais de quoi est-il question ?</p>
              <a [routerLink]="['/article-drama']" class="btn btn-dark">Lire</a>
            </div>
            <div class="card-footer text-muted">#netflix #kdrama #vost</div>
          </div>
          </div>-->
        <!-- </div> -->
      <!-- </div> -->

      <!-- Films -->
      <div class="tab-pane fade show active" id="films" role="tabpanel" aria-labelledby="films-tab">

        <!-- cards -->
        <div class="row">

          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Film d'animation</div>
              <div class="card-body">
                <h5 class="card-title">Clochette et la créature légendaire</h5>
                <p class="card-text">Rêves-tu d'une vie solitaire ?</p>
                <a [routerLink]="['/article-clochette_et_la_creature_legendaire']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#clochette #disney #amitié</div>
            </div>
          </div>

          <!-- article Le cercle des poètes disparus -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Film</div>
              <div class="card-body">
                <h5 class="card-title">Le cercle des poètes disparus</h5>
                <p class="card-text text-center">Un étudiant timide et un professeur marginal font leur rentrée à la prestigieuse école de Welton</p>
                <a [routerLink]="['/article-le_cercle_des_poetes_disparus']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#Star #CarpeDiem #Poesie #RobinWilliams</div>
            </div>
          </div>

          <!-- article coco -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Film d'animation</div>
              <div class="card-body">
                <h5 class="card-title">Coco</h5>
                <p class="card-text text-center">L'histoire d'un petit passionné de musique qui malgré lui part à la rencontre de l'histoire de sa famille</p>
                <a [routerLink]="['/article-coco']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#Disney #Mexique</div>
            </div>
          </div>

          <!-- article The greatest showman -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Film</div>
              <div class="card-body">
                <h5 class="card-title">The greatest showman</h5>
                <p class="card-text text-center">Quand le rêve devient réalité</p>
                <a [routerLink]="['/article-the_greatest_showman']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#Disney #comediemusicale #spectacle</div>
            </div>
          </div>

          <!-- article Dans l'ombre de Mary -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Film</div>
              <div class="card-body">
                <h5 class="card-title">Dans l'ombre de Mary</h5>
                <p class="card-text text-center">La promesse d'un père à ses filles</p>
                <a [routerLink]="['/article-dans-l-ombre-de-mary']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#disney #film #MaryPoppins</div>
            </div>
          </div>

          <!-- article la planète au trésor -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Film d'animation</div>
              <div class="card-body">
                <h5 class="card-title">La planète au trésor</h5>
                <p class="card-text text-center">Les prémices de la grandeur</p>
                <a [routerLink]="['/article-la-planete-au-tresor']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#disney #aventures #pirates</div>
            </div>
          </div>

        </div>
      </div>

      <div class="tab-pane fade" id="documentaires" role="tabpanel" aria-labelledby="documentaires-tab">
        <!-- cards -->
        <div class="row">

          <!-- article Marvel project heros  -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Documentaire</div>
              <div class="card-body">
                <h5 class="card-title">Marvel's Hero Project</h5>
                <p class="card-text text-center">Mise à l'honneur et récompense de l'engagement d'un enfant dans un projet associatif</p>
                <a [routerLink]="['/article-marvel-project-heros']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#famille #herosmarvel #espoir</div>
            </div>
          </div>

          <!-- article Animal kingdom -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Série-Documentaire</div>
              <div class="card-body">
                <h5 class="card-title">Animal Kingdom</h5>
                <p class="card-text text-center">Découvrez les coulisses des parcs animaliers Disney</p>
                <a [routerLink]="['/article-disney-animal-kingdom']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#disney #animaux #nationalgeographic #parcanimalier</div>
            </div>
          </div>

          <!-- article -->
          <!-- <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Série</div>
              <div class="card-body">
                <h5 class="card-title">Big little lies</h5>
                <p class="card-text">Trois femmes, mères à la vie parfaite... En surface seulement</p>
                <a [routerLink]="['/article-big-little-lies']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#ocs #mere #femmes</div>
            </div>
          </div> -->

        <!-- article -->
        <!-- <div class="col-12 col-lg-4">

          <div class="card text-center" style="max-width: 540px;">
            <div class="card-header">
              Culture
            </div>
            <div class="card-body">
              <h5 class="card-title">Qu'est-ce-qu'un drama?</h5>
              <p class="card-text">Les dramas sont de plus en présents sur Netflix, mais de quoi est-il question ?</p>
              <a [routerLink]="['/article-drama']" class="btn btn-dark">Lire</a>
            </div>
            <div class="card-footer text-muted">#netflix #kdrama #vost</div>
          </div>
          </div>-->
        </div>
      </div>

      <div class="tab-pane fade" id="star" role="tabpanel" aria-labelledby="star-tab">

        <!-- cards -->
        <div class="row">

          <!-- article Marvel project heros  -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Actu/News</div>
              <div class="card-body">
                <h5 class="card-title">Disney Plus : Section Star</h5>
                <p class="card-text text-center">Star la nouvelle section de Disney Plus</p>
                <a [routerLink]="['/article-section_star']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#Disney #adultes #news</div>
            </div>
          </div>

        </div>
      </div>


    </div>

  </div>
</section>
