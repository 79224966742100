
<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>La Convocation</h2>
        <h5>les abus sexuels dans le cadre universitaire, 22 novembre 2020</h5>

        <p>
          Film africain de 2020 - durée 2h31 - pour les 16 ans et plus
        </p>

        <br>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-la-convocation.jpg" alt="">
        </div>

        <br>

        <p>
          Petite critique sur ce film nigérien qui m’a retourné l’estomac et l’esprit : La convocation.
        </p>

        <p>
          <strong class="emphase">Le pitch:</strong>
          Une jeune nigériane fait tout son possible pour faire reconnaître le viol qu’elle a subie par un professeur émérite au sein de l’université dans laquelle elle étudie.
          <br>
          J'ai beaucoup aimé ce film inspiré de faits réels concernant la lutte contre le harcèlement sexuel sur les campus universitaires. De l'histoire aux décors, à la musique en passant par les acteurs qui sont tous excellents, je valide l’ensemble.
        </p>

        <p>
          L’actrice Temi Otedola joue magnifiquement bien avec émotion, retenue et vérité. Elle redore la parole des femmes victimes de ces faits odieux. Elles sont trop souvent considérées à grand tort « d’aguicheuses » limite à mériter leurs sort sordides.
        </p>

        <p>
          N’oublions jamais que nos filles, nos femmes, nos mères peuvent être victimes de cette cruauté qui laissera à jamais des cicatrices béantes tant physiques que morales et que nous les hommes avons également un rôle important à jouer : protéger, secourir, réconforter et dénoncer toutes actes violents faits aux femmes au quotidien.
          <br>
          Désolé si je vous casse un peu l’ambiance mais il faut aussi s’insurger TOUS ensemble face à l’ignominie indescriptible de tels faits relatés à travers ce genre de film que propose Netflix.
        </p>

        <p>
          Bon visionnage sur Netflix !
        </p>

        <br>

        <p class="signature text-end"><strong>Charly</strong></p>
      </article>

    </div>

  </div>

</section>





