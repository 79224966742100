import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-dear-white-people',
  templateUrl: './article-dear-white-people.component.html',
  styleUrls: ['./article-dear-white-people.component.sass']
})
export class ArticleDearWhitePeopleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
