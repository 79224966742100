import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-soa',
  templateUrl: './article-soa.component.html',
  styleUrls: ['./article-soa.component.sass']
})
export class ArticleSoaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
