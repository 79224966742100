import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-tribulations-phil',
  templateUrl: './article-tribulations-phil.component.html',
  styleUrls: ['./article-tribulations-phil.component.sass']
})
export class ArticleTribulationsPhilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
