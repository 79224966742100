import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-family-business',
  templateUrl: './family-business.component.html',
  styleUrls: ['./family-business.component.sass']
})
export class FamilyBusinessComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
