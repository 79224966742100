<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Dracula</h2>
        <h5>Mini-série en trois épisodes, 12 mai 2021</h5>

        <p>
          BBC/Netflix, 2020 Scénaristes: Steven Moffat et Mark Gatiss (NB: ce dernier apparaît dans le rôle de Frank)Série US
        </p>

        <p>
          Fans de Dracula, ne vous envolez pas ! Vous n’aurez pas le romantisme flamboyant d’un Coppola, ni la sordide noirceur d’un Murneau, mais ce triptyque vaut largement le détour.
          Puisant dans l’essence du roman de Bram Stoker, avec un Comte, fourbe, cruel et sans humanité, cette mini-série introduit de joyeuses surprises, telles qu’un Van Helsing personnifié par une nonne énergique et anticonformiste.
        </p>

        <p>
          <strong class="emphase">(ATTENTION SPOILS)</strong>
        </p>

        <p>
          Les deux premiers épisodes s’articulent autour de plusieurs récits et de leurs flash-backs, sur un rythme parfois lent, parfois brusque. A nous d’être patients quand c’est nécessaire, la récompense ne tardera pas.
        </p>

        <p>
          La première histoire est celle d’un J. Harker pathétique, à bout de forces (et même plus), recueilli après son évasion par un couvent. A peine en état de parler, il décrit point par point son séjour dans le château du Comte Dracula (précisons-le pour les lecteurs qui sortiraient d’un coma de 124 ans: en tant qu’employé de notaire, envoyé à l’occasion de l’acquisition d’un immeuble en Angleterre par le Comte).
        </p>

        <p>
          Naïf et plein d’assurance à son arrivée, il va progressivement réaliser qu’il est prisonnier et victime du vieillard, à mesure que celui-ci rajeunit et lui dévoile - avec un cynisme constant - son cruel mode de vie, pour finir par se nourrir ouvertement de lui.
        </p>

        <p>
          La soeur Agatha Van Helsing écoute Harker avec attention, le questionne, et finalement lui dévoile - devant son épouse accablée - sa triste condition, le poussant vers un suicide de plus (encore raté - il n’a vraiment pas de chance en ce domaine), alors même que Dracula himself arrive en grande forme au couvent, détournant quelques références à la lycanthropie au passage. Avant qu’il ne dézingue les nonnes façon slasher, une belle confrontation a lieu avec la soeur Agatha, prémices de leur fascination mutuelle et d’une curieuse partie d’échecs entre la soeur écœurée mais passionnée par les forces du mal - et Dracula, non-humain abject, mais bizarrement attaché à son adversaire.
        </p>

        <p>
          Dans le deuxième épisode, le conteur est, cette fois, le Comte, et son interlocutrice est soeur Agatha, captivée puis se découvrant captive. Il nous embarque sur le navire voguant vers l’Angleterre, longue traversée donnant lieu à un huis-clos à la limite de l’intrigue policière, puisque le Comte y aligne les meurtres, derrière une mince couche de bonnes manières. En contrepoint au premier épisode, cette fois encore, le récit va rejoindre la réalité, et s’achever par une nouvelle confrontation des deux personnages principaux, violente et tragique, jusqu’à leur fin commune: soeur Agatha se noie, et Dracula finit au fond de l’eau dans sa caisse de terre natale.
        </p>

        <p>
          Mais le deuxième volet ne se termine par là: il nous offre quelques minutes finales splendidement WTF, qui introduisent une dernière partie, elle, complètement moderne. Nos deux adversaires ont, chacun à leur manière, traversé le temps et vont se retrouver pour jouer leur duel final...
        </p>

        <p>
          La série brille par sa construction, à la fois changeante et répétitive, à l’image de la reprise - souvent empreinte de second degré - qu’elle fait de du mythe du prince des vampires, revisité et enrichi. Les deux acteurs principaux (Claes Bang et Dolly Wells) sont parfaits dans leurs rôles. On en ressort un peu frustré, un peu en manque: le format semble trop court pour développer toutes les idées, toutes les trouvailles, toutes les promesses. On aurait voulu davantage. Il y a pire comme défaut.
        </p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/VfOHhqCVvfo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>S. Prietula</strong></p>
      </article>

    </div>

  </div>

</section>






