import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-your-lie-in-april',
  templateUrl: './article-your-lie-in-april.component.html',
  styleUrls: ['./article-your-lie-in-april.component.sass']
})
export class ArticleYourLieInAprilComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
