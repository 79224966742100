<section class="article-ocs py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Chernobyl</h2>
        <h5>Les dessous d'une catastrophe nucléaire, 14 janvier 2021</h5>

        <p>
          « Chernobyl » est une mini-série composée de cinq épisodes, écrite par Craig Mazin et diffusée à partir du 7 mai 2019 sur OCS en France.
        </p>

        <p>
          <strong class="emphase">De quoi ça parle :</strong> Comme son titre l’indique, cette mini-série retrace l’histoire de la plus grande catastrophe nucléaire et ses conséquences humaines et environnementales.
        </p>

        <p>

          <strong class="emphase">Mon avis :</strong> L’atmosphère anxiogène et tragique rendent ce show captivant et nous empêche de quitter l’écran malgré certaines scènes « chocs ». Le courage et le sacrifice des Hommes sur place vous plongent dans la torpeur. Une impression de (re)vivre ce drame avec eux au travers des journaux télévisés. Le réalisme de la série fait qu’elle aurait toute sa place en cours d’histoire à partir de la fin de collège.
        </p>

        <p>
          <strong class="emphase">Anecdote :</strong> Le show a reçu l’Emmy Awards « Meilleure série limitée » en 2019 et le Golden Globe 2020 de la « Meilleure mini-série ou meilleur téléfilm ».
        </p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/skCiDK542aM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>Angèle</strong></p>
      </article>
    </div>
  </div>
</section>

