import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-je-veux-manger-ton-pancreas',
  templateUrl: './article-je-veux-manger-ton-pancreas.component.html',
  styleUrls: ['./article-je-veux-manger-ton-pancreas.component.sass']
})
export class ArticleJeVeuxMangerTonPancreasComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
