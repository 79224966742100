<section class="amazon-video">
  <div class="container py-5">

    <!-- amazon-video title -->
    <div class="row text-center title">
      <h1 class="amazon-video-h1">Fans de <strong class="amazon-video-title">Amazon Prime</strong></h1>
    </div>

    <!-- navbar -->
    <ul class="nav nav-tabs" id="myTab" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" id="series-tab" data-bs-toggle="tab" href="#series" role="tab" aria-controls="series" aria-selected="true">Série</a>
      </li>
      <!-- <li class="nav-item" role="presentation">
        <a class="nav-link" id="films-tab" data-bs-toggle="tab" href="#films" role="tab" aria-controls="films" aria-selected="false">Films</a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="documentaires-tab" data-bs-toggle="tab" href="#documentaires" role="tab" aria-controls="documentaires" aria-selected="false">Documentaires</a>
      </li> -->
    </ul>

    <div class="tab-content" id="myTabContent">

      <!-- Series -->
      <div class="tab-pane fade show active" id="series" role="tabpanel" aria-labelledby="serie-tab">
        <!-- cards -->
        <div class="row">

          <!-- article Animal Kingdom -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header text-center">Série</div>
              <div class="card-body">
                <h5 class="card-title">Animal Kingdom</h5>
                <p class="card-text">Laissez vous adopter par cette famille hors norme</p>
                <a [routerLink]="['/article-animal-kingdom']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#AmazonPrimeVideo #gang</div>
            </div>
          </div>

          <!-- article -->
          <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Série dramatique</div>
              <div class="card-body">
                <h5 class="card-title">This is us</h5>
                <p class="card-text">Une ôde à la vie et à ses maux</p>
                <a [routerLink]="['/article-this-is-us']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#AmazonPrimeVideo #serie #emotions</div>
            </div>
          </div>

          <!-- article -->
          <!-- <div class="col-12 col-lg-4">
            <div class="card text-center" style="max-width: 540px;">
              <div class="card-header">Série</div>
              <div class="card-body">
                <h5 class="card-title">Big little lies</h5>
                <p class="card-text">Trois femmes, mères à la vie parfaite... En surface seulement</p>
                <a [routerLink]="['/article-big-little-lies']" class="btn btn-dark">Lire</a>
              </div>
              <div class="card-footer text-muted">#ocs #mere #femmes</div>
            </div>
          </div> -->

        <!-- article -->
        <!-- <div class="col-12 col-lg-4">

          <div class="card text-center" style="max-width: 540px;">
            <div class="card-header">
              Culture
            </div>
            <div class="card-body">
              <h5 class="card-title">Qu'est-ce-qu'un drama?</h5>
              <p class="card-text">Les dramas sont de plus en présents sur Netflix, mais de quoi est-il question ?</p>
              <a [routerLink]="['/article-drama']" class="btn btn-dark">Lire</a>
            </div>
            <div class="card-footer text-muted">#netflix #kdrama #vost</div>
          </div>
          </div>-->
        </div>
      </div>
      <div class="tab-pane fade" id="films" role="tabpanel" aria-labelledby="films-tab">
        ...
      </div>
      <div class="tab-pane fade" id="documentaires" role="tabpanel" aria-labelledby="documentaires-tab">
        ...
      </div>
    </div>




  </div>
</section>
