<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Your lie in April </h2>
        <h5>La rencontre entre un jeune pianiste dépressif et une jeune violoniste extravertie, 30 mars 2021</h5>
        <p>
          « Your lie in April » est une série animée dramatique, composée de 22 épisodes de 25 minutes chacun en moyenne.
          <br>
        </p>
        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-your_lie_in_april.jpg" alt="">
        </div>
        <p>
          <strong class="emphase">De quoi ça parle :</strong> Après la mort de sa mère, Arima, prodige du piano n’a plus goût à la musique ni à aucun autre loisir d’ailleurs. C’est sans compter sur sa rencontre avec Miyazono, violoniste extravertie qui excelle aussi dans son domaine.
        </p>

        <p>
          <strong class="emphase">Mon avis :</strong> Les personnages sont hauts en couleurs et en charisme. On s’attache vite à eux et l’envie de panser leurs blessures nous submerge. Miyazono est déroutante tant les rebondissements qui l’accompagne vous empêche de vous ennuyer une seule seconde.
        </p>
        <p>
          La bande son est incroyablement vibrante de créativité et vous entraine en un rien de temps dans le tumultueux monde des artistes avec une douceur exceptionnelle.
        </p>
        <p>
          Enfin l’émotion prendra le dessus sur le comique et vous fera verser un océan de larmes. Bon ok, peut-être pas un océan, mais les larmes couleront.
        </p>
        <p>
          A noter également que les graphismes sont magnifiquement réalisés et que les dialogues sont extrêmement bien écrits.
        </p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/hCeYtIAdOdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>Angèle</strong></p>
      </article>
    </div>
  </div>
</section>
