<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Omar SY le nouveau gentleman cambrioleur !</h2>
        <h5>Omar SY dans la série Netflix “Lupin dans l’ombre d'Arsène", 2 janvier 2021</h5>
        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-lupin-img1.jpg" alt="">
        </div>
        <p>À travers cette série française Netflix, souhaite donner de la modernité modernité à la célèbre collection de livres Arsène LUPIN, de Maurice LEBLANC.

       Cette série de livre parle d'un cambrioleur de la belle époque (1880-1914) qui use des ses talents de gentleman et de son ingéniosité afin de détrousser ses victimes, jusqu'au jour où il découvre une énigme qui pourrait bien lui faire découvrir un véritable trésor. Son nom, parmis tant d'autres, est Arsène LUPIN

      L'œuvre de Maurice LEBLANC a déjà été reprise notamment pour des séries, des films et l'animation japonaise, et aujourd'hui par Netflix.
      </p>

      <div class="article_img">
        <img class="img-fluid" src="../../../../../assets/img/article-lupin-img2.jpg" alt="">
      </div>


      <p> Dans la bande annonce de la série Netflix (voir ci-dessous), on peut voir plusieurs clins d'oeil à l’oeuvre original comme le célèbre collier de la reine ou encore le cambriolage du Louvre, on peut voir également qu'il possède un livre où il y a inscrit « Arsène LUPIN gentleman cambrioleur". Sûrement l'héritage dont il parle, Omar SY utilisera le nom de Arsene DIOP.
      </p>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/Y3tVDKuORi8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
      <p>
        Dans la seconde bande annonce on découvre le plan d'action, pour la suite… c'est le 8 janvier 2021 sur Netflix !
      </p>

      <iframe width="560" height="315" src="https://www.youtube.com/embed/gCmuYqeeNpc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>

      <p class="signature text-end"><strong>Aghilles</strong></p>
    </article>



  </div>

  </div>

</section>


