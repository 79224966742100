<section class="contact py-5">

  <div class="container">

    <!-- contact title -->
    <div class="row">
      <h1 class="contact-h1">Formulaire de <strong class="contact-title">Contact</strong></h1>
    </div>

    <!-- form contact row -->
    <div class="row">

      <div class="form-contact">

        <!-- left-side img -->
        <div class="contact-img">
          <img src="../../../../assets/img/img-01.png" alt="enveloppe">
        </div>

        <!-- form -->
        <form class="form-fields validate-form" (submit)="submitForm($event)">
          <fieldset [attr.disabled]="sended ? true : null">
            <!-- name -->
            <div class="input-container validate-input" data-validate = "Name is required">
              <input class="input-field" type="text" name="name"  placeholder="Nom">
                <span class="focus-input-field "></span>
                <span class="symbol-input100 material-icons">person</span>
              </div>

              <!-- email -->
              <div class="input-container validate-input" data-validate = "Valid email is required: ex@abc.xyz">
                <input class="input-field" type="text" name="email" placeholder="Email">
                <span class="focus-input-field"></span>
                <span class="symbol-input100 material-icons">alternate_email</span>
              </div>

              <!-- subject -->
              <div class="input-container validate-input" data-validate = "subject is required">
                <input class="input-field" type="text" name="subject" placeholder="Objet">
                <span class="focus-input-field"></span>
                <span class="symbol-input100 material-icons">
                  subject
                </span>
              </div>

              <!-- message -->
              <div class="input-container validate-input" data-validate = "Message is required">
                <textarea class="input-field" name="message" placeholder="Message"></textarea>
                <span class="focus-input-field"></span>
              </div>

              <ul *ngIf="errors.length" class="errors">
                <li *ngFor="let error of errors">{{ error }}</li>
              </ul>

              <!-- submit button -->
              <div class="contact-form-btn" *ngIf="!sended">
                <button class="btn-black">Envoyer <span class="material-icons send">forward_to_inbox</span>
                </button>
              </div>
              <!-- submit button -->
              <p class="contact-form-success" *ngIf="sended && !errors.length">
                Votre message a bien été envoyé
              </p>
          </fieldset>
        </form>

      </div>
    </div>
  </div>
</section>



