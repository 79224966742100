<section class="team py-5">
  <div class="container">

    <!-- Team title -->
    <div class="row ">
        <h1 class="team-h1">La TEAM <strong class="team-title">FDN Community</strong></h1>
    </div>
    <!-- Onglets -->
    <ul class="team-list-tabs">
      <!-- Les Fondateurs -->
      <li [ngClass]="{'actived': tabActived === 1}">
        <a href="#pills-fondateurs" (click)="changeTab($event, 1)">Les Fondateurs</a>
      </li>
      <!-- les Admins / modo -->
      <li [ngClass]="{'actived': tabActived === 2}">
        <a href="#pills-modos" (click)="changeTab($event, 2)">Les Admins et Modérateurs</a>
      </li>
      <!-- les renforts -->
      <li [ngClass]="{'actived': tabActived === 3}">
        <a href="#pills-renforts" (click)="changeTab($event, 3)">Les Spécialistes</a>
      </li>
    </ul>
    <!-- Contenus -->
    <!-- Les Fondateurs -->
    <ul class="team-list-content" *ngIf="tabActived === 1">
      <!-- Charly -->
      <li>
        <img src="../../../../assets/img/avatar_charly3.jpg">
        <h3 class="title">Charly</h3>
        <span class="role">Administrateur</span>
        <p class="description">Communication et gestion des groupes</p>
      </li>
      <!-- Kevin -->
      <li>
        <img src="../../../../assets/img/avatar_kevin.jpg">
        <h3 class="title">Kevin</h3>
        <span class="role">Administrateur</span>
        <p class="description">Evolution numérique FDN</p>
      </li>
      <!-- Dylan -->
      <li>
        <img src="../../../../assets/img/avatar_dylan.jpg">
        <h3 class="title">Dylan</h3>
        <span class="role">Administrateur</span>
        <p class="description">Gestion de la page FDN</p>
      </li>
    </ul>

    <!-- les Admins / modo -->
    <ul class="team-list-content" *ngIf="tabActived === 2">
      <!-- Neopha -->
      <li>
        <img src="../../../../assets/img/avatar_neopha.jpg">
        <h3 class="title">Neopha</h3>
        <span class="role">Administratrice</span>
        <p class="description">Co-Gestion des groupes FDN Community</p>
      </li>
      <!-- Angèle -->
      <li>
        <img src="../../../../assets/img/avatar_angèle.jpg">
        <h3 class="title">Angèle</h3>
        <span class="role">Administratrice et Modératrice</span>
        <p class="description">Admin du groupe connexe Disney Plus <br>Modératrice des groupes connexes</p>
      </li>
      <!-- Chris -->
      <li>
          <img src="../../../../assets/img/avatar_Chris.jpg">
          <h3 class="title">Chris</h3>
          <span class="role">Administratrice et Modératrice</span>
          <p class="description">Admin du groupe connexe OCS <br>Modératrice des groupes connexes</p>
      </li>
      <!-- Aghilles -->
      <li>
        <img src="../../../../assets/img/avatar_aghilles.jpg">
        <h3 class="title">Aghilles</h3>
        <span class="role">Administreur et Modérateur</span>
        <p class="description">Admin du groupe connexe Amazon Prime
          <br>Modérateur des groupes connexes
          <br>Support animations et visuels FDN
        </p>
      </li>
      <!-- Lyvia -->
      <li>
        <img src="../../../../assets/img/avatar_lyvia.jpg">
        <h3 class="title">Lyvia</h3>
        <span class="role">
          Administratrice et Modératrice
        </span>
        <p class="description">Admin du groupe FB FDN Canada
          <br>Modératrice des groupes connexes
          <br>Webmaster du site FDN Community
        </p>
      </li>
      <!-- Karrol -->
      <li>
        <img src="../../../../assets/img/avatar_placeholder.png">
        <h3 class="title">Karrol</h3>
        <span class="role">
          Administratrice
        </span>
        <p class="description">Admin du groupe FDN Live
          <br>Support du compte Instagram FDN
        </p>
      </li>

      <!-- Alex -->
      <li>
        <img src="../../../../assets/img/avatar_alex.jpg">
        <h3 class="title">Alex</h3>
        <span class="role">
          Modérateur
        </span>
        <p class="description">Modérateur des groupes connexes</p>
      </li>
      <!-- Laura -->
      <li>
        <img src="../../../../assets/img/avatar_laura.jpeg">
        <h3 class="title">Laura</h3>
        <span class="role">
          Modératrice
        </span>
        <p class="description">Modératrice des groupes connexes</p>
      </li>
      <!-- Nadège -->
      <li>
        <img src="../../../../assets/img/avatar_nadege.jpeg">
        <h3 class="title">Nadège</h3>
        <span class="role">
          Modératrice
        </span>
        <p class="description">Modératrices des groupes connexes</p>
      </li>
    </ul>

    <ul class="team-list-content renforts" *ngIf="tabActived === 3">
      <!-- Simon -->
      <li>
        <img src="../../../../assets/img/avatar_simon2.jpeg">
        <h3 class="title">Simon</h3>
        <span class="role">Développeur web</span>
        <p class="description">Petit gaulois exploité au fond de la cave pour réaliser le site web FDN</p>
      </li>
      <!-- Clotilde -->
      <li>
        <img src="../../../../assets/img/avatar_placeholder.png">
        <h3 class="title">Clothilde</h3>
        <span class="role">Graphiste et photographe</span>
        <p class="description">Créatrice des supports visuels numériques</p>
      </li>
    </ul>
  </div>
</section>

