<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Sons of anarchy...</h2>
        <h5>Un club de bikers mais pas que..., 20 décembre 2020</h5>
        <p>
          « Sons of Anarchy » est une série télévisée américaine composée de 92 épisodes (7 saisons).
          <br>
          <p>
            <strong class="emphase">De quoi ça parle :</strong> La série raconte comment un club de motards craint mais respecté, fait régner l’ordre dans une petite ville fictive nommée Charming.           Mais ce n’est pas qu’une histoire de motards. C’est aussi une guerre des territoires, de l’humour, de l’amour, de la trahison, les liens familiaux et amicaux.
          </p>
          <br>
          <p>
            <strong class="emphase">Mon avis :</strong> « Sons of Anarchy » est une série au cours de laquelle on ne s’ennuie pas une seule seconde. C’est une tornade de sentiments qui s’abat sur vos épaules au travers de cette fiction si bien réalisée. Un savoureux mélange d’action et d’émotion.
          </p>

          <br>
          <p>
            <strong class="emphase">Anecdote :</strong> Afin d’être au plus proche de la réalité concernant le club de bikers, Kurt Sutter s’est immergé au sein du club Hells Angels. L’un des membres de ce dernier, David Labrava, a d’ailleurs été conseiller technique sur le tournage et a même rejoint le casting en incarnant le personnage Happy.
          </p>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-soa.jpg" alt="">
        </div>

        <p class="signature text-end"><strong>Angèle</strong></p>
      </article>
    </div>
  </div>
</section>
