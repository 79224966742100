
<section class="article-ocs py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Dans l’ombre de Mary</h2>
        <h5>La véritable histoire d’une nounou fantastique</h5>

        <p>
          « Dans l’ombre de Mary » est un film d’une durée de 2h05 réalisé par John Lee Hancock et sorti le 5 mars 2014.
        </p>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-dans-lombre-de-mary.jpg" alt="">
        </div>

        <p>
          <strong class="emphase">De quoi ça parle :</strong> Le film raconte comment Walt Disney aura mis 20 ans à tenir une promesse faite à ses filles : mettre en scène Mary Poppins, l’héroïne préférée de ses enfants.
          <br>
          Mais ce n’est pas tout. On y suit un Walt Disney déterminé à obtenir les droits d’adaptation auprès d’une auteure qui a peur de voir son héroïne déchirée et malmenée par Hollywood. On y suit également la naissance de Mary Popins.
        </p>

        <p>
          <strong class="emphase">Mon avis :</strong> Tom Hanks (incarnant Walt Disney) et Emma Thompson (incarnant P.L Travers) sont magnifiques et tiennent leurs rôles avec une justesse remarquable. A noter également la très belle performance de Collin Farrell et Paul Giamatti. Ce quatuor porte ce film à la perfection. L’histoire est tout simplement prenante, touchante et drôle.
          <br>
          Que vous aimiez ou non Mary Poppins, vous ne la verrez plus de la même manière.
        </p>

        <p>
          <strong class="emphase">Anecdote :</strong> C’est le premier long métrage de la Walt Disney Company mettant en scène Walt Disney lui-même.
        </p>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/K-hBQ6J2xrY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <p class="signature text-end"><strong>Angèle</strong></p>
      </article>
    </div>
  </div>
</section>


