<section class="article-netflix py-5">
  <div class="container">
    <div class="row justify-content-center">
      <article class="col-sm-8">
        <h2>Les tribulations culinaires de Phil</h2>
        <h5>Voyages et rencontres autour de la cuisine du monde, 14 janvier 2021</h5>

        <iframe width="560" height="315" src="https://www.youtube.com/embed/HQxA8atOzz0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <span>Générique du programme</span>

        <p>
          Ce que j’aime dans la vie ce sont les voyages, les rencontres…
        </p>

        <p>
          Je n’étais pas trop « Reportages » ou « émissions » culinaires, mais je suis tombée, un peu par hasard, sur <strong class="emphase">Les tribulations culinaires de Phil</strong> (Philip Rosenthal, que personnellement je ne connaissais pas du tout). Et je ne regrette pas un seul instant.
        </p>

        <div class="article_img">
          <img class="img-fluid" src="../../../../../assets/img/article-tribulations-phil.jpg" alt="">
        </div>

        <p>
          Avec beaucoup d’humour et de gourmandise Phil nous partage ses rencontres à travers le monde, on ressent avec beaucoup de simplicité sa grande passion pour la nourriture (c’est qu’il est un peu glouton le Phil) mais aussi son amour de l’Autre. De belles images, de belles découvertes, de beaux échanges, qui donnent vraiment envie de faire le tour du monde avec lui à découvrir ces merveilles qu’il nous sert sur un plateau !!
        </p>

        <p>
          Il y a actuellement 4 saisons, soit 22 épisodes… Allez si… un seul regret me concernant !! Il n’y en a pas assez et c’est frustrant quand une saison se termine, hâte de voir s’il y a une prochaine saison !! Bon voyage à tous !!
        </p>

        <p class="signature text-end"><strong>Zira</strong></p>
      </article>

    </div>

  </div>

</section>


