
<header id="header">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark px-4">

    <!-- logo FDN -->
    <a class="navbar-brand" routerLink="home">
      <img class="logoFDN" src="../../../../assets/img/logoFDN.jpg" alt="logo FDN">
      <span class="FDN">FDN Community</span>
    </a>

    <!-- burger menu -->
    <button type="button" class="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#myNavbar">
        <span class="toggler-icon material-icons">menu</span>
    </button>

    <div class="collapse navbar-collapse ml-auto" id="myNavbar">
      <ul class="navbar-nav">

        <!-- Accueil -->
        <li class="nav-item active">
          <a class="nav-link" routerLink="home">Accueil</a>
        </li>

        <!-- Présentation FDN -->
        <li class="nav-item">
          <a class="nav-link" routerLink="presentation-fdn">A propos de FDN</a>
        </li>

        <!-- svod -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown">SVOD</a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="svod-netflix">Netflix</a></li>
            <li><a class="dropdown-item" routerLink="svod-ocs">OCS</a></li>
            <li><a class="dropdown-item" routerLink="svod-amazon-video">Amazon Prime Videos</a></li>
            <li><a class="dropdown-item" routerLink="svod-disney-plus">Disney +</a></li>
          </ul>
        </li>

        <!-- team fdn -->
        <li class="nav-item">
          <a class="nav-link" routerLink="team">La Team</a>
        </li>

        <!-- Contact -->
        <li class="nav-item">
          <a class="nav-link" routerLink="contact">Contact</a>
        </li>
      </ul>

      <!-- logo e-shop -->
      <!--
        <div class="nav-info-items d-none d-lg-flex">

          <div class="nav-info align-items-center d-flex justify-content-between mx-lg-5">
          <span class="info-icon mx-lg-3 material-icons">phone</span>
          <p class="mb-0">+ 123 456 789</p>
        </div>
        <div id="cart-info" class="nav-info align-items-center cart-info d-flex justify-content-between mx-lg-5">
          <span class="cart-info__icon mr-lg-3 material-icons">shopping_cart</span>
          <p class="mb-0 text-capitalize">
            <span id="item-count">2</span>
            produits - €
            <span class="item-total">10.49
            </span>
        </div>
      </div>-->
    </div>
  </nav>
</header>
<router-outlet></router-outlet>
