import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-la-planete-au-tresor',
  templateUrl: './article-la-planete-au-tresor.component.html',
  styleUrls: ['./article-la-planete-au-tresor.component.sass']
})
export class ArticleLaPlaneteAuTresorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
