import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-article-coco',
  templateUrl: './article-coco.component.html',
  styleUrls: ['./article-coco.component.sass']
})
export class ArticleCocoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
