import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-fdn-presentation',
  templateUrl: './main-fdn-presentation.component.html',
  styleUrls: ['./main-fdn-presentation.component.sass']
})
export class MainFdnPresentationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
